import React from 'react'

const notfound = () => {
  return (
    <div>
      404
    </div>
  )
}

export default notfound
